
  import { PartnerRequest } from "@/store/modules/partner-request.store";
  import { formatDate } from "@/utils/formatters";
  import { Component, Vue } from "vue-property-decorator";
  import { Action, Mutation } from "vuex-class";
  import { FormCheckbox } from "@/components/forms";
  import { FormFile } from "@/components/forms/index";
  import { PartnerService } from "@/store/modules/partner.store";
  import { PartnerType } from "@/store/modules/partner.store";

  @Component({
    components: {
      FormCheckbox,
      FormFile,
      RequestDocuments: () => import("@/views/partner/request-documents.vue"),
      RequestAppointments: () => import("@/views/partner/request-appointments.vue"),
      RequestNotes: () => import("@/views/partner/request-notes.vue"),
      TextPart: () => import("@/components/dossier/property-info/text.vue"),
      AreaPart: () => import("@/components/dossier/property-info/area.vue"),
      AreaSqPart: () => import("@/components/dossier/property-info/area_sq.vue"),
      YesNoPart: () => import("@/components/dossier/property-info/yes-no.vue"),
      documentCard: () => import("@/components/document/card-old.vue"),
      updateDocumentModal: () => import("@/components/modals/update-document.vue"),
      previewMediaModal: () => import("@/components/modals/preview-media.vue"),
      GeneralNav: () => import("@/components/general/nav.vue"),
      GeneralNavLink: () => import("@/components/general/nav-link.vue"),
    },
  })
  export default class PageDashboard extends Vue {
    @Action("partner-request/read") readPartnerRequests!: (payload: number) => Promise<PartnerRequest>;
    @Mutation("activity/SET_VIEWING") setViewingActivity!: (payload: any) => void;

    formatDate = formatDate;

    PartnerType = PartnerType;

    partnerRequest: PartnerRequest | null = null;

    async mounted() {
      this.partnerRequest = await this.readPartnerRequests(Number(this.$route.params.id));

      this.setViewingActivity(this.partnerRequest.activity);
    }

    getLabelForService(service: PartnerService) {
      if (!service.addons || service.addons.length === 0) {
        return service.title;
      }

      if (service.addons.length === 1) {
        return `${service.title} - ${service.addons[0].title}`;
      }

      return `${service.title} - (${service.addons.map((t) => t.title).join(" & ")}) `;
    }
  }
